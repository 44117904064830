<template>
  <v-container fluid class="px-2">
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
   :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <h1>Acceso Rápido</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6" class="py-0">
        <v-select
          v-model="selectedCountry"
          :items="countries"
          placeholder="Escoge el pais"
          outlined
          item-text="name"
          item-value=".key"
          rounded
        />
      </v-col>

      <v-col cols="12" md="6" class="py-0">
        <v-select
          v-model="selectedCity"
          :items="cities"
          placeholder="Escoge la ciudad"
          outlined
          :disabled="!selectedCountry"
          item-text="name"
          item-value=".key"
          rounded
        />
      </v-col>
    </v-row>

    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <v-card class="mx-auto" elevation="2">
          <v-card-text>
            <div>Requiere Dirección</div>
            <v-data-table
              :headers="headers"
              :items="categories"
              :items-per-page="5"
              :loading="loading"
              :search="$store.state.search"
              :sort-desc="false"
              item-key=".key"
              sort-by="name"
              :footer-props="{
                itemsPerPageOptions: [5, 10, 25, 50],
              }"
            >
              <template v-slot:loading>
                <div class="ma-5" style="position: relative">
                  <div class="inner-lottie">
                     <lottie
                      :options="defaultOptions"
                      :height="150"
                      :width="150"
                      v-on:animCreated="handleAnimation"
                    />
                    <p class="subtitle-1 black--text">Cargando Data</p>
                  </div>
                  <v-skeleton-loader
                    ref="skeleton"
                    type="table-tbody"
                    class="mx-auto d-none d-md-block"
                  ></v-skeleton-loader>
                </div>
              </template>
              <template v-slot:[`item.requireAddress`]="{ item }">
                <v-switch
                  @change="
                    switchControlChanged(
                      { requireAddress: item.requireAddress },
                      item,
                      'requireAddress'
                    )
                  "
                  v-model="item.requireAddress"
                ></v-switch>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <v-card class="mx-auto" elevation="2">
          <v-card-text>
            <div>Mínimo de Productos</div>
            <v-data-table
              :headers="minimumProductsHeaders"
              :items="categories"
              :items-per-page="5"
              :loading="loading"
              :search="minProductSearch"
              :sort-desc="false"
              item-key=".key"
              sort-by="name"
              :footer-props="{
                itemsPerPageOptions: [5, 10, 25, 50],
              }"
            >
              <template v-slot:loading>
                <div class="ma-5" style="position: relative">
                  <div class="inner-lottie">
                       <lottie
                      :options="defaultOptions"
                      :height="150"
                      :width="150"
                      v-on:animCreated="handleAnimation"
                    />
                    <p class="subtitle-1 black--text">Cargando Data</p>
                  </div>
                  <v-skeleton-loader
                    ref="skeleton"
                    type="table-tbody"
                    class="mx-auto d-none d-md-block"
                  ></v-skeleton-loader>
                </div>
              </template>
              <template v-slot:top>
                <v-text-field
                  v-model="minProductSearch"
                  label="Buscar"
                  class="mx-4"
                ></v-text-field>
              </template>

              <template v-slot:[`item.maxProductsAllowed`]="{ item }">
                <v-text-field
                  v-model="item.maxProductsAllowed"
                  type="number"
                  outlined
                  dense
                  rounded
                  :min="0"
                  class="mt-3"
                ></v-text-field>
              </template>

              <template v-slot:[`item.maxETA`]="{ item }">
                <v-text-field
                  v-model="item.maxETA"
                  type="number"
                  outlined
                  dense
                  rounded
                  :min="0"
                  class="mt-3"
                ></v-text-field>
              </template>

              <template v-slot:[`item.maxPickupDay`]="{ item }">
                <v-text-field
                  v-model="item.maxPickupDay"
                  type="number"
                  outlined
                  dense
                  rounded
                  :min="0"
                  class="mt-3"
                ></v-text-field>
              </template>

              <template v-slot:[`item.maxDropoffDay`]="{ item }">
                <v-text-field
                  v-model="item.maxDropoffDay"
                  type="number"
                  outlined
                  dense
                  rounded
                  :min="0"
                  class="mt-3"
                ></v-text-field>
              </template>

              <template v-slot:[`item.maxDayCostKm`]="{ item }">
                <v-text-field
                  v-model="item.maxDayCostKm"
                  type="number"
                  outlined
                  dense
                  rounded
                  :min="0"
                  class="mt-3"
                ></v-text-field>
              </template>

              <template v-slot:[`item.maxExtraKmCharge`]="{ item }">
                <v-text-field
                  v-model="item.maxExtraKmCharge"
                  type="number"
                  outlined
                  dense
                  rounded
                  :min="0"
                  class="mt-3"
                ></v-text-field>
              </template>

              <template v-slot:[`item.isMax`]="{ item }">
                <v-switch v-model="item.isMax"></v-switch>
              </template>

              <template v-slot:[`item.options`]="{ item }">
                <v-btn
                  @click="saveMinProduct(item)"
                  small
                  color="primary"
                  :disabled="categories.length === 0"
                >
                  Guardar
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <v-card class="mx-auto" elevation="2">
          <v-card-text>
            <div>Mal Tiempo</div>
            <v-row>
              <v-col cols="6" class="mx-auto pb-0">
                <v-select
                  v-model="badWeather"
                  :items="badWeatherCongestedSelectItems"
                  item-text="text"
                  item-value="value"
                  outlined
                  rounded
                  dense
                ></v-select>
              </v-col>
              <v-col cols="6" class="mx-auto pb-0">
                <v-text-field
                  label="mensaje mal tiempo"
                  outlined
                  rounded
                  v-model="badWeatherText"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-data-table
              ref="badweather"
              dense
              :headers="weatherHeaders"
              :items="categories"
              :items-per-page="5"
              :loading="loading"
              :search="weatherSearch"
              :sort-desc="false"
              item-key=".key"
              sort-by="name"
              :footer-props="{
                itemsPerPageOptions: [5, 10, 25, 50],
              }"
            >
              <template v-slot:loading>
                <div class="ma-5" style="position: relative">
                  <div class="inner-lottie">
                      <lottie
                      :options="defaultOptions"
                      :height="150"
                      :width="150"
                      v-on:animCreated="handleAnimation"
                    />
                    <p class="subtitle-1 black--text">Cargando Data</p>
                  </div>
                  <v-skeleton-loader
                    ref="skeleton"
                    type="table-tbody"
                    class="mx-auto d-none d-md-block"
                  ></v-skeleton-loader>
                </div>
              </template>

              <template v-slot:top>
                <v-text-field
                  v-model="weatherSearch"
                  label="Buscar"
                  class="mx-4"
                  append-icon="mdi-magnify"
                ></v-text-field>
              </template>

              <template v-slot:[`item.badWeatherFee`]="{ item }">
                <v-text-field
                  v-model="item.badWeatherFee"
                  label="Recargo Mal Tiempo"
                  type="number"
                ></v-text-field>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              :disabled="categories.length === 0"
              elevation="2"
              class="mt-1 ml-auto mr-5 mb-5"
              :loading="loading"
              @click="saveFunction('badWeather')"
            >
              Actualizar mal tiempo
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <v-card class="mx-auto" elevation="2">
          <v-card-text>
            <div>Congestionamiento</div>
            <v-row>
              <v-col cols="6" class="mx-auto pb-0">
                <v-select
                  v-model="congested"
                  :items="badWeatherCongestedSelectItems"
                  item-text="text"
                  item-value="value"
                  outlined
                  rounded
                  dense
                ></v-select>
              </v-col>

              <v-col cols="6" class="mx-auto pb-0">
                <v-text-field
                  label="mensaje congestionamiento"
                  outlined
                  rounded
                  v-model="congestedText"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-data-table
              ref="congested"
              dense
              :headers="congestedHeaders"
              :items="categories"
              :items-per-page="5"
              :loading="loading"
              :search="congestedSearch"
              :sort-desc="false"
              item-key=".key"
              sort-by="name"
              :footer-props="{
                itemsPerPageOptions: [5, 10, 25, 50],
              }"
            >
              <template v-slot:loading>
                <div class="ma-5" style="position: relative">
                  <div class="inner-lottie">
                      <lottie
                      :options="defaultOptions"
                      :height="150"
                      :width="150"
                      v-on:animCreated="handleAnimation"
                    />
                    <p class="subtitle-1 black--text">Cargando Data</p>
                  </div>
                  <v-skeleton-loader
                    ref="skeleton"
                    type="table-tbody"
                    class="mx-auto d-none d-md-block"
                  ></v-skeleton-loader>
                </div>
              </template>

              <template v-slot:top>
                <v-text-field
                  v-model="congestedSearch"
                  label="Buscar"
                  class="mx-4"
                ></v-text-field>
              </template>

              <template v-slot:[`item.congestedETA`]="{ item }">
                <v-text-field
                  v-model="item.congestedETA"
                  label="Nuevo Tiempo de Espera (minutos)"
                  type="number"
                ></v-text-field>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              :disabled="categories.length === 0"
              elevation="2"
              class="mt-1 ml-auto mr-5 mb-5"
              :loading="loading"
              @click="saveFunction('congested')"
            >
              Actulizar Congestionamiento
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import ledgerMixin from "@/mixins/ledgerMixin";
import { db } from "@/firebase";

export default {
  name: "quick-settings",
  components: {
    lottie: Lottie,
  },
  mixins: [lottieSettings, ledgerMixin],
  data() {
    return {
      loading: true,
      saving: false,
      snackbar: false,
      snackbarText: "",
      cities: [],
      selectedCity: "",
      countries: [],
      selectedCountry: "",
      categories: [],
      weatherSearch: "",
      congestedSearch: "",
      minProductSearch: "",
      badWeather: null,
      badWeatherText: "",
      congested: null,
      congestedText: "",
      headers: [
        {
          text: "Nombre",
          value: "name",
          class: "nowrap",
        },
        {
          text: "Requiere Dirección",
          value: "requireAddress",
          class: "nowrap",
        },
      ],
      minimumProductsHeaders: [
        {
          text: "Nombre",
          value: "name",
          class: "nowrap",
        },
        {
          text: "Máximo Productos Permitidos",
          value: "maxProductsAllowed",
          class: "nowrap",
        },
        {
          text: "Nuevo Tiempo de Espera (minutos)",
          value: "maxETA",
          class: "nowrap",
        },

        {
          text: "Nuevo Costo por Recoger",
          value: "maxPickupDay",
          class: "nowrap",
        },
        {
          text: "Nuevo Costo por Entregar",
          value: "maxDropoffDay",
          class: "nowrap",
        },

        {
          text: "Nuevo Costo por KM Recorrido",
          value: "maxDayCostKm",
          class: "nowrap",
        },
        {
          text: "Nuevo Costo por Recargo sobre KM",
          value: "maxExtraKmCharge",
          class: "nowrap",
        },
        {
          text: "Aplicar",
          value: "isMax",
          class: "nowrap",
        },
        {
          value: "options",
          align: "end",
          sortable: false,
        },
      ],
      badWeatherCongestedSelectItems: [
        { text: "", value: null },
        { text: "Si", value: true },
        { text: "No", value: false },
      ],
      weatherHeaders: [
        {
          text: "Categorías",
          value: "name",
          class: "nowrap",
        },
        {
          text: "Recargo Mal Tiempo",
          value: "badWeatherFee",
          class: "nowrap",
          sortable: false,
        },
      ],
      congestedHeaders: [
        {
          text: "Categorías",
          value: "name",
          class: "nowrap",
        },
        {
          text: "Nuevo Tiempo de Espera (minutos)",
          value: "congestedETA",
          class: "nowrap",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.$store.commit("setSearchTerm", "categorías");
    this.$store.commit("setSearch", "");
    this.$store.state.visibleSearch = true;

    this.$binding(
      "countries",
      db.collection("countries").where("deleted", "==", false)
    ).then(() => (this.loading = false));
  },
  methods: {
    switchControlChanged(newValue, item, field) {
      let prevData = Object.assign({}, newValue);
      prevData[field] = !newValue[field];
      this.saving = true;

      db.collection("categories")
        .doc(item[".key"])
        .update(newValue)
        .then(() => {
          this.saving = false;
          this.snackbarText = "Información actualizada.";
          this.snackbar = true;
          this.createLedger({
            source_id: item[".key"],
            source_type: "categories",
            currentValue: newValue,
            prevValue: prevData,
          });
        })
        .catch(() => {
          this.saving = false;
          this.snackbarText = "Ocurrió un error, inténtelo nuevamente.";
          this.snackbar = true;
        });
    },
    saveFunction(params) {
      if (confirm("¿Estás seguro de actualizar las categorías?")) {
        if (params === "congested") {
          let congestedItems =
            this.$refs.congested.$children[0].filteredItems.map(
              ({ id, congestedETA }) => ({ id, congestedETA })
            );

          for (const item of congestedItems) {
            if (item.congestedETA == null) item.congestedETA = 0;
            if (this.congested !== null) {
              item.isCongested = this.congested;
              item.congestedText = this.congestedText;
            }
          }
          this.submit(congestedItems);
        } else {
          let badWeatherItems =
            this.$refs.badweather.$children[0].filteredItems.map(
              ({ id, badWeatherFee }) => ({
                id,
                badWeatherFee: Number(badWeatherFee),
              })
            );

          for (const item of badWeatherItems) {
            if (item.badWeatherFee == null) item.badWeatherFee = 0;
            if (this.badWeather !== null) {
              item.isBadWeather = this.badWeather;
              item.badWeatherText = this.badWeatherText;
            }
          }
          this.submit(badWeatherItems);
        }
      }
    },
    submit(items) {
      this.saving = true;
      let batch = db.batch();
      items.forEach((item) => {
        const docRef = db.collection("categories").doc(item.id);
        delete item.id;
        batch.update(docRef, item);
      });
      batch
        .commit()
        .then(() => {
          this.saving = false;
          this.snackbar = true;
          this.snackbarText = "categorías actualizadas";
          this.badWeather = null;
          this.badWeatherText = "";
          this.congested = null;
          this.congestedText = "";
        })
        .catch((error) => {
          this.saving = false;
          this.snackbar = true;
          this.snackbarText = `Ocurrio un error: ${error}`;
        });
    },
    saveMinProduct(item) {
      let data = {
        maxProductsAllowed: item.maxProductsAllowed || 0,
        maxETA: item.maxETA || 0,
        maxPickupDay: item.maxPickupDay || 0,
        maxDropoffDay: item.maxDropoffDay || 0,
        maxDayCostKm: item.maxDayCostKm || 0,
        maxExtraKmCharge: item.maxExtraKmCharge || 0,
        isMax: item.isMax || false,
      };
      this.saving = true;

      db.collection("categories")
        .doc(item[".key"])
        .update(data)
        .then(() => {
          this.saving = false;
          this.snackbarText = "Información actualizada.";
          this.snackbar = true;
        })
        .catch(() => {
          this.saving = false;
          this.snackbarText = "Ocurrió un error, inténtelo nuevamente.";
          this.snackbar = true;
        });
    },
  },
  watch: {
    selectedCountry(countryId) {
      this.categories = [];
      this.selectedCity = "";
      this.$binding(
        "cities",
        db
          .collection("cities")
          .where("deleted", "==", false)
          .where("countryId", "==", countryId)
      );
    },
    selectedCity(cityId) {
      this.loading = true;
      this.$binding(
        "categories",
        db
          .collection("categories")
          .where("deleted", "==", false)
          .where("city", "==", cityId)
      ).then((categories) => {
        categories.forEach((category) => {
          category.id = category[".key"];
        });
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>
.inner-lottie {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
